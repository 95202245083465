let colorLookup = {
    'AUD': 'rgb(55,86,35)',
    'CAD': 'rgb(160,0,0)',
    'NZD': 'rgb(0,0,0)',
    'CHF': 'rgb(106,22,96)',
    'GBP': 'rgb(0,32,96)',
    'USD': 'rgb(179,219,177)',
    'XAU': 'rgb(191,143,0)',
    'EUR': 'rgb(155,194,230)',
    'JPY': 'rgb(165,165,165)',
    'EURUSD': 'rgb(155,194,230)',
    'EURJPY': 'rgb(165,165,165)',
    'XAUUSD': 'rgb(191,143,0)',
    'USDCHF': 'rgb(106,22,96)',
    'GBPUSD': 'rgb(0,32,96)',
    'AUDUSD': 'rgb(179,219,177)',
    'CADJPY': 'rgb(0,0,0)',
    'USDJPY': 'rgb(48, 222, 236)',
    'GBPJPY': 'rgb(217, 147, 115)',
    'CHFJPY': 'rgb(114, 127, 194)',
    'EURAUD': 'rgb(13, 120, 81)',
    'DynamicStat': 'rgb(5, 184, 73)',
    'Vol': 'rgb(5, 179, 146)',
    'Flow': 'rgb(163, 68, 116)',
    'Trend': 'rgb(140, 73, 183)',
    'Total': 'rgb(179,219,177)',
    'Floating': 'rgb(165,165,165)',
    'Booked': 'rgb(88,109,119)',
    'Average': 'rgb(147,204,255)',
    'Max': 'rgb(255,153,153)',
    'USD Fund': 'rgb(154,175,224)',
    'AGG': 'rgb(170,17,73)',
    'DXY': 'rgb(211,31,130)',
    'HFRX': 'rgb(19,39,219)',
    'MSCI': 'rgb(203,229,100)',
    'S&P 500': 'rgb(69,25,170)',
    'SG CTA': 'rgb(122,79,209)',
    'NZDUSD': 'rgb(232,99,188)',
    'EURGBP': 'rgb(206,41,21)',
    'AUDNZD': 'rgb(37,73,248)',
    'NZDJPY': 'rgb(49,69,20)',
    'AUDJPY': 'rgb(52,227,25)',
    'Flow-1': 'rgb(99,117,244)',
    'Stat-5': 'rgb(47,36,6)',
    'Vol-1': 'rgb(217,136,213)',
    'Trend-2': 'rgb(71,62,158)',
    'Trend-1': 'rgb(10,144,152)',
    'Trend-3': 'rgb(235,186,62)',
    'Stat-3': 'rgb(9,147,234)',
    'Stat-2': 'rgb(124,24,49)',
    'Stat-1': 'rgb(128,217,149)',
    'Stat-4': 'rgb(33,183,49)',
    'Trend-4': 'rgb(80,223,235)',
    'Stat': 'rgb(166,201,233)',
    'Trend 2.0': 'rgb(166,201,233)',
    'DynamicStat-5': 'rgb(235,68,124)',
    'DynamicStat-6': 'rgb(26,222,195)',
    'DynamicStat-7': 'rgb(69,87,120)',
    'DynamicStat-1': 'rgb(216,14,154)',
    'DynamicStat-3': 'rgb(193,78,26)',
    'DynamicStat-9': 'rgb(46,72,233)',
    'DynamicStat-12': 'rgb(65,55,85)',
    'DynamicStat-2': 'rgb(65,55,85)',
    'Flow-4': 'rgb(79,57,176)',
    'Stat-6': 'rgb(188,133,176)',
    'AUDCAD': 'rgb(147,102,101)',
    'AUDCHF': 'rgb(244,111,184)',
    'AUDSGD': 'rgb(62,17,44)',
    'CADCHF': 'rgb(59,80,70)',
    'CADHKD': 'rgb(226,199,44)',
    'CHFSGD': 'rgb(125,249,172)',
    'EURCAD': 'rgb(41, 216, 229)',
    'EURCHF': 'rgb(162, 204, 67)',
    'EURHKD': 'rgb(221, 179, 115)',
    'EURNOK': 'rgb(84, 6, 229)',
    'EURNZD': 'rgb(198, 94, 41)',
    'EURSEK': 'rgb(160, 32, 49)',
    'EURSGD': 'rgb(60, 252, 153)',
    'EURTRY': 'rgb(169, 247, 160)',
    'GBPCAD': 'rgb(237, 163, 194)',
    'GBPCHF': 'rgb(224, 94, 249)',
    'GBPNZD': 'rgb(135, 252, 10)',
    'HKDJPY': 'rgb(221, 99, 171)',
    'NZDCAD': 'rgb(246, 255, 122)',
    'NZDCHF': 'rgb(234, 119, 203)',
    'SGDJPY': 'rgb(219, 224, 67)',
    'TRYJPY': 'rgb(45, 144, 153)',
    'USDCAD': 'rgb(183, 132, 1)',
    'USDDKK': 'rgb(239, 173, 67)',
    'USDMXN': 'rgb(115, 244, 171)',
    'USDNOK': 'rgb(142, 155, 26)',
    'USDSEK': 'rgb(61, 237, 120)',
    'USDSGD': 'rgb(162, 204, 67)',
    'USDTRY': 'rgb(221, 179, 115)',
    'USDZAR': 'rgb(84, 6, 229)',
    'XAGUSD': 'rgb(198, 94, 41)',
    'DKK': 'rgb(235, 255, 175)',
    'HKD': 'rgb(19, 242, 223)',
    'MXN': 'rgb(201, 232, 118)',
    'NOK': 'rgb(134, 134, 216)',
    'SGD': 'rgb(69, 24, 191)',
    'ZAR': 'rgb(219, 42, 39)',
    'SEK': 'rgb(10, 178, 27)',
    'TRY': 'rgb(217, 45, 239)',
    'XAG': 'rgb(252, 135, 85)',
    'Yesterday': 'rgb(179,219,177)',
    'Month': 'rgb(88,109,119)',
    'Week': 'rgb(165,165,165)',
    'Momentum': 'rgb(198,161,39)',
    'Live Track Record': 'rgb(154,175,224)',
    'Managed Account 5': 'rgb(154,175,224)',
    'Track Record': 'rgb(154,175,224)',
    'Breakout': '#dadcf0',
    'GC': '#ffd700',
    'HG': '#6d823a',
    'PA': '#76c8e3',
    'PL': '#406dae',
    'SI': '#C0C0C0',
    'CL': '#566d8d',
    'HO': '#da9933',
    'NG': '#6d402c',
    'RB': '#7958ad',
    'ES': '#429579',
    'EM': '#f2eddb',
    'NK': '#209559',
    'NQ': '#d2efca',
    'TY': '#ce617e',
    'ZC': '#d8b044',
    'ZL': '#a0827f',
    'ZM': '#e9e00e',
    'ZS': '#260644',
    'ZW': '#02494e',
    'HE': '#6f704c',
    'LE': '#25a9d3',
    'Metals': '#43464B',
    'Energy': '#C80815',
    'Equity': '#46523C',
    'Agriculture': '#003366',
    'HFT': '#710220',
    'Pattern': '#bfba5f',
    'AD': '#902f09',
    'BP': '#4902c0',
    'CD': '#397531',
    'EU': '#5949eb',
    'JP': '#e03d85',
    'NE': '#c851c0',
    'PX': '#0ee141',
    'FX': '#4d171e'
};

export function getColor(key) {
    return colorLookup[key];
}