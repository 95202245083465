<template>
    <div class="traded-minutes-widget">
        <div class="col-12 d-flex align-items-center justify-content-between mb-2">
            <div class="col-2 d-flex align-items-center justify-content-between mb-2" style="padding-left:0px !important; padding-right:0px !important">
                Optimiser: 
                <b-form-select class="mr-2" v-model="currentOptimiser" :options="optimiserSelect" @change="optimiserChange"></b-form-select>
            </div>
            <b-button variant="light" class="mr-2" @click="onExport">
                Export
            </b-button>
         </div>
        <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" style="margin:0 auto" show-empty>
            <template slot="empty">
                No results found
            </template>
            
        </b-table>
    </div>
</template>

<script>
import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'traded-minutes-stats',
    data() {
        return {
            tableOptions: {
                columns: [],
                items: []
            },
            optimiserSelect:[],
            currentOptimiser:-1,
        };
    },
    props:{
        ID:{
            type: Number,
            default:null
        },
        parsedOptimiser:{
            type:String,
            default:null
        },
        parsedOptimiserCompare:{
            type:String,
            default:null
        }
    },
    components:{

    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('tradedminutes', this.onTradedMinutes);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('tradedminutes', this.onTradedMinutes);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let optimiser = this.currentOptimiser;
            if(this.parsedOptimiser != null){
                optimiser = this.parsedOptimiser
            }
            let optimiserCompare = null
            if(this.parsedOptimiserCompare != null){
                optimiserCompare = this.parsedOptimiserCompare
            }
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'TradedMinutes',
                args: optimiser != -1 ? (optimiserCompare != null && optimiserCompare != -1 ? 
                {optimiserId: optimiser,optimiserIdCompare:optimiserCompare} : {optimiserId: optimiser})  : {}
            });
        },
        optimiserChange(newOptimiser){
            if(!isNaN(Number(newOptimiser))){
                this.requests();
            }
        },
        onTradedMinutes(event){
            if(event.response.hasOwnProperty("optimisers")){
                let optimisers = JSON.parse(event.response.optimisers)
                if(Object.keys(optimisers).length <= 0){
                    return;
                }
                let values = Object.values(optimisers)
                let ids = values.map(x=> {return x.id})

                let formattedValues = [];
                for(let value of values){
                    formattedValues.push({value:value.id,text:value.name})
                }
                this.optimiserSelect = formattedValues;

                let current = values.find(x=>x.id == this.currentOptimiser);
                if(current == null || current == undefined){
                    this.currentOptimiser = Math.max(...ids);
                }
                this.$emit("getOptimisers", formattedValues, this.currentOptimiser, "tradedminutes")
            }
            if(event.response.hasOwnProperty("data")){
                let data = JSON.parse(event.response.data)
                if(Object.keys(data).length <= 0){
                    return;
                }
                let columns = [];
                
                let formatter = function(value){return (isNaN(Number(value))?value : (Number(value)*100).toFixed(3)) + "%"}
                columns.push({
                    key:"period",
                    label:"Hour/Day",
                    sortable:true
                })
                let keys = Object.keys(data);
                for(let key of keys){
                    columns.push({
                        key:key,
                        label:key,
                        sortable:true,
                        formatter:formatter
                    })
                }

                let items = [];
                keys = Object.keys(Object.values(data)[0]);

                keys.forEach((period,i)=>{
                   let itemBuilder = []
                   itemBuilder.push(period)
                   for(let market of Object.keys(data)){
                       itemBuilder.push(data[market][period])
                   }
                    let item = {}
                    for(let i = 0;i< columns.length;i++){
                        item[columns[i].key] = itemBuilder[i]
                    }

                    items.push(item);
                })

                this.tableOptions.columns = columns;
                this.tableOptions.items = items;

                this.$emit("getData", items, this.ID)
                
            }
            if(event.response.hasOwnProperty("comparison")){
                let data = JSON.parse(event.response.comparison)
                if(Object.keys(data).length <= 0){
                    return;
                }
                let columns = [];
                
                let formatter = function(value){return (isNaN(Number(value))?value : (Number(value)*100).toFixed(3)) + "%"}
                columns.push({
                    key:"period",
                    label:"Hour/Day",
                    sortable:true
                })
                let keys = Object.keys(data);
                for(let key of keys){
                    columns.push({
                        key:key,
                        label:key,
                        sortable:true,
                        formatter:formatter
                    })
                }

                let items = [];
                keys = Object.keys(Object.values(data)[0]);

                keys.forEach((period,i)=>{
                   let itemBuilder = []
                   itemBuilder.push(period)
                   for(let market of Object.keys(data)){
                       itemBuilder.push(data[market][period])
                   }
                    let item = {}
                    for(let i = 0;i< columns.length;i++){
                        item[columns[i].key] = itemBuilder[i]
                    }

                    items.push(item);
                })

                this.$emit("getRawData", items, this.ID)
                
            }
        },
        onExport(){
            let headers = {};
            let emptyObj = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
                emptyObj[column.key] = "";
            }

            let items = []

            for(let x in this.tableOptions.items){
                items.push(this.tableOptions.items[x])
            }
            //let items = map(this.tableOptions.items,clone)
            
            exportCsv(headers, items, 'TradePeriodStats');
        }
    }
}
</script>